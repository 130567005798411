class Registry {
  data: any;
  constructor() {
    this.data = {};
  }
  addAll = (list: any) => {
    if (!list) return;
    this.data = {};
    list.forEach((element: any) => {
      this.data[element.id] = element;
    });
  };
  add(id: any, obj: any) {
    this.data[id] = obj;
  }
  del(id: any) {
    delete this.data[id];
  }
  get(id: any) {
    return this.data[id];
  }
  delAll() {
    this.data = {};
  }
}
const instanceRegistry = new Registry();
export default instanceRegistry;
