export const normaliseDimensions = (max: number, dimensions: any): any => {
  debugger;
  if (!dimensions) return null;
  const result = { w: max, h: max };
  if (dimensions.h > dimensions.w) {
    result.w = (dimensions.w / dimensions.h) * 200;
    result.h = max;
  } else {
    result.h = (dimensions.h / dimensions.w) * 200;
    result.w = max;
  }
  return result;
};
