import { Image, Button, Text } from "@mantine/core";
function ProductHunt() {
  return (
    <a
      href="https://www.producthunt.com/posts/profile-me-by-softcloud?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-profile&#0045;me&#0045;by&#0045;softcloud"
      target="_blank"
    >
      <img
        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=361644&theme=light"
        alt="Profile&#0032;Me&#0032;by&#0032;Softcloud - Create&#0032;amazing&#0032;profile&#0032;pictures | Product Hunt"
        width="200"
        height="54"
      />
    </a>
  );
}
export default ProductHunt;
