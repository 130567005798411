export default class ZoomPanHelper {
  pan(dx: any, dy: any, transformMatrix: any) {
    transformMatrix.e -= dx;
    transformMatrix.f -= dy;
  }

  zoom(scale: any, cx: any, cy: any, transformMatrix: any) {
    transformMatrix.e -= cx;
    transformMatrix.f -= cy;

    transformMatrix.a *= scale;
    transformMatrix.b *= scale;
    transformMatrix.c *= scale;
    transformMatrix.d *= scale;
    transformMatrix.e *= scale;
    transformMatrix.f *= scale;

    transformMatrix.e += cx;
    transformMatrix.f += cy;
  }
}
