import html2canvas from "html2canvas";
const saveAs = (blob: any, fileName: any) => {
  var elem: any = window.document.createElement("a");
  elem.href = blob;
  elem.download = fileName;
  elem.style = "display:none;";
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === "function") {
    elem.click();
  } else {
    elem.target = "_blank";
    elem.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      })
    );
  }
  URL.revokeObjectURL(elem.href);
  elem.remove();
};

const exportAsPicture = (element: any) => {
  return new Promise((resolve, reject) => {
    html2canvas(element.current)
      .then((canvas) => {
        var image = canvas.toDataURL("image/png", 1.0);
        saveAs(image, "exported-vis.png");
        resolve(image);
      })
      .catch(() => {
        reject();
      });
  });
};
export default exportAsPicture;
