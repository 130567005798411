import React, { useRef, useState } from "react";
import "./sample.image.cmp.css";
import exportAsPicture from "../../helpers/download.helper";
import { sleep } from "../../helpers/app.helper";
import { ReactComponent as CloudSvg } from "./assets/cloud-download.svg";

const FinalImage = ({
  imageSrc,
  imageTr,
  imageDim,
  backGround,
  paternSrc,
}: {
  imageSrc: any;
  imageTr: any;
  imageDim: any;
  backGround: any;
  backGroundIsImage: boolean;
  paternSrc: any;
}) => {
  const mainDiv = useRef(null);
  const [downloading, setDownloading] = useState<boolean>(false);

  const doExportAsPicture = async () => {
    try {
      setDownloading(true);
      debugger;
      await sleep(1000);
      await exportAsPicture(mainDiv);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div className="main-container">
      <div
        ref={mainDiv}
        className={
          downloading
            ? "selfy-img-container download-animated"
            : "selfy-img-container"
        }
        style={{ backgroundImage: backGround }}
      >
        {paternSrc ? <img className="selfy-img" src={paternSrc} /> : null}
        <img
          className="selfy-img selfy-img-offset"
          src={imageSrc}
          style={{
            transform: `matrix(${imageTr})`,
            width: imageDim.w,
            height: imageDim.h,
          }}
        />
      </div>
      <div className="dowload-button" onClick={doExportAsPicture}>
        <CloudSvg />
      </div>
    </div>
  );
};
export default FinalImage;
