import React from "react";
import Consts from "../helpers/ViewPortConst";
import SpacialHelper from "../helpers/SpacialHelper";
import "./RubberBand.css";

interface IRubberBandProps {
  viewport?: any;
  selection?: any;
  doRubberMouseDown: Function;
}

class RubberBand extends React.Component<IRubberBandProps> {
  constructor(props: any) {
    super(props);
    this.calculateCoordinates = this.calculateCoordinates.bind(this);
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    return (
      nextProps.selectedItem ||
      nextProps.viewport.viewportTr !== this.props.viewport.viewportTr ||
      nextProps.viewport.selectedTr !== this.props.viewport.viewportTr ||
      nextProps.viewport.parentTr !== this.props.viewport.viewportTr
    );
  }

  calculateCoordinates() {
    let box = SpacialHelper.calculateRubberBandPosition(
      this.props.viewport.selection.matrix,
      this.props.viewport.selection.box,
      this.props.viewport.viewportMtx,
      null
    );
    return { x: box.x, y: box.y, w: box.w, h: box.h, transform: box.transform };
  }

  render() {
    let coordinates = this.calculateCoordinates();
    const { selection } = this.props;

    if (!selection) return null;

    return (
      <svg
        id="Rubberband"
        className="rubberBand-Container"
        width="100%"
        height="100%"
        onMouseDown={(event) => {
          this.props.doRubberMouseDown(event, Consts.MODE_RUBER_BAND_MOVE);
        }}
      >
        <g transform={`matrix(${coordinates.transform})`}>
          <rect
            ref="ruberBandRect"
            className="rubberBand"
            x={coordinates.x}
            y={coordinates.y}
            width={coordinates.w}
            height={coordinates.h}
          />

          <circle
            className="rubberBandHandleRotate"
            cx={coordinates.x + coordinates.w / 2}
            cy={coordinates.y}
            r={Consts.RUBBER_BAND_HANDLE_SIZE * 2}
            onMouseDown={(event) => {
              this.props.doRubberMouseDown(
                event,
                Consts.MODE_RUBER_BAND_ROTATE
              );
            }}
          />

          {/* <rect
            ref="ruberBandBottomLeft"
            className="rubberBandHandle"
            x={
              coordinates.x + coordinates.w / 2 - Consts.RUBBER_BAND_HANDLE_SIZE
            }
            y={coordinates.y - Consts.RUBBER_BAND_HANDLE_SIZE}
            width={Consts.RUBBER_BAND_HANDLE_SIZE * 3}
            height={Consts.RUBBER_BAND_HANDLE_SIZE * 3}
            onMouseDown={(event) => {
              this.props.doRubberMouseDown(
                event,
                Consts.MODE_RUBER_BAND_ROTATE
              );
            }}
          /> */}
        </g>
      </svg>
    );
  }
}

export default RubberBand;
