import { useState, useRef } from "react";
import { Slider } from "@mantine/core";
import "./editor.image.cmp.css";
import BackgroundModal from "../background/background.modal";
import grid from "./assets/grid.svg";
import ZoomPan from "../zoompan";
import { Button, createStyles } from "@mantine/core";
const useStyles = createStyles((theme) => ({
  control: {
    position: "absolute",
    width: 250,
    left: "calc(50% - 125px)",
    bottom: 45,
  },
}));

const EditorImage = ({
  imageSrc,
  imageDim,
  backGround,
  setBackGround,
  onChangePosition,
  reset,
}: {
  imageSrc: any;
  imageDim: any;
  backGround: any;
  setBackGround: any;
  onChangePosition: any;
  reset: any;
}) => {
  const { classes, theme } = useStyles();
  const backHome = () => {
    reset();
  };
  const onChange = (element: any, positionData: any) => {
    onChangePosition && onChangePosition(positionData);
  };

  return (
    <div className="editor-container-main">
      {/* <div className="editor-title">Edit image</div> */}
      <div className="editor-container">
        <div className="editor-img-container"></div>

        <ZoomPan onChange={onChange} imageDim={imageDim}>
          <img
            className="editor-img"
            src={imageSrc}
            style={{ width: imageDim.w, height: imageDim.h }}
          />
        </ZoomPan>
        <img className="img-grid" src={grid} />
        <div className="editor-mask" />
      </div>
      <BackgroundModal setBackGround={setBackGround} />
      <Button
        className={classes.control}
        size="md"
        radius="xl"
        onClick={backHome}
      >
        Back
      </Button>
    </div>
    //      <label>Scale</label>
    //      <Slider value={value} onChange={setValue} />
    //      <label>Rotation</label>
    //      <Slider value={value} onChange={onChangeZoom} />
  );
};
export default EditorImage;


