import React, { useEffect, useRef, useState } from "react";
import { MantineProvider } from "@mantine/core";
import { removeBgFromFile } from "./helpers/background.helper";
import "./App.css";
import EditorImage from "./components/editor/editor.image.cmp";
import SampleImage from "./components/sample/sample.image.cmp";
import patterns from "./components/patterns/patters.helper";
import { backgroundsImages } from "./components/background/background.const";
import { normaliseDimensions } from "./helpers/image.helper";
import UploadComponent from "./components/uploader/uploader.cmp";
import applogo from "./assets/applogo.png";
import sideLeftImg from "./assets/headersideleft.png";
import siderightImg from "./assets/headersideright.png";
import FooterComponent from "./components/footer/footer.component";
import BuymeButton from "./components/buyme/buyme.button";
import PrivacyPolicyPage from "./components/terms/privacy-policy";
import TermsConditionPage from "./components/terms/terms-and-conditions";
import ProductHunt from "./components/producthunt/producthunt.button";
const MAX_IMAGE_SIDE = 200;

export default function App() {
  const [imageSrc, setImageSrc] = useState<string>("");
  const [imageLoading, setimageLoading] = useState<boolean>(false);
  const [imageDim, setImageDim] = useState<any>({ w: 0, h: 0 });
  const [imageTr, setImageTr] = useState<string>("1, 0, 0, 1, 0, 0");
  const [backGround, setbackGround] = useState<string>(
    "linear-gradient(to right, rgb(22, 160, 133), rgb(244, 208, 63));"
  );

  const handleInputChange = async (files: any) => {
    setimageLoading(true);
    const result: any = await removeBgFromFile(files[0]);
    const dimensions = normaliseDimensions(MAX_IMAGE_SIDE, result.dimension);
    debugger;
    setImageDim(dimensions);
    setImageSrc(result.image);
    setimageLoading(false);
  };
  const onChangePosition = (positionData: any) => {
    setImageTr(positionData.transform);
  };
  const reset = () => {
    setImageSrc("");
    setImageDim({ w: 0, h: 0 });
  };

  const renderSamplePatterns = () => {
    return patterns.map((pattern, index) => {
      return (
        <SampleImage
          key={index}
          imageSrc={imageSrc}
          imageTr={imageTr}
          imageDim={imageDim}
          backGround={backGround}
          backGroundIsImage={false}
          paternSrc={pattern}
        ></SampleImage>
      );
    });
  };
  const renderSampleImage = () => {
    return backgroundsImages.map((image, index) => {
      return (
        <SampleImage
          key={index}
          imageSrc={imageSrc}
          imageTr={imageTr}
          imageDim={imageDim}
          backGround={image}
          backGroundIsImage={true}
          paternSrc={null}
        ></SampleImage>
      );
    });
  };

  const renderMain = () => {
    return (
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <div className="App">
          <div
            className={imageSrc ? "header-app-editor" : "header-app"}
            style={{ background: backGround }}
          >
            <img className="header-app-title" src={applogo} />
            <div className="header-button-buy">
              <BuymeButton />
            </div>
            <div className="header-product-hunt">
              <ProductHunt />
            </div>

            {!imageSrc ? (
              <div className="sample-container">
                <img className="img-side-left" src={sideLeftImg} />
                <img className="img-side-right" src={siderightImg} />
                <UploadComponent
                  imageLoading={imageLoading}
                  onChange={handleInputChange}
                />
              </div>
            ) : (
              <div className="sample-container">
                <EditorImage
                  imageSrc={imageSrc}
                  imageDim={imageDim}
                  backGround={backGround}
                  onChangePosition={onChangePosition}
                  setBackGround={setbackGround}
                  reset={reset}
                ></EditorImage>
              </div>
            )}
          </div>
          <div className={imageSrc ? "content-container" : ""}>
            <div className="sample-container">{renderSamplePatterns()}</div>
            <div className="sample-container">{renderSampleImage()}</div>
            <FooterComponent />
          </div>
        </div>
      </MantineProvider>
    );
  };

  const renderApp = () => {
    const path = window.location.pathname;
    switch (path) {
      case "/terms-and-conditions":
        return <TermsConditionPage />;
      case "/privacy-policy":
        return <PrivacyPolicyPage />;

      default:
        return renderMain();
    }
  };

  return renderApp();
}
