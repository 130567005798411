import { createStyles } from "@mantine/core";

export default createStyles((theme) => ({
  footer: {
    padding: "2rem 4rem",
    backgroundColor: "#322E2E",
    color: "#fff",
  },
  logo: {
    width: 43.5,
  },
  link: {
    textDecoration: "none",
    color: "#fff",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  listItem: {
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      marginBottom: 7,
    },
  },
  footerLogo: {
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      marginBottom: "4rem",
    },
  },
  socials: {
    gap: 10,
  },
  socialIcon: {
    color: "rgba(255,255,255, 0.5)",
    fontSize: 20,
    "&:hover": {
      color: "rgba(255,255,255, 0.9)",
    },
  },
}));
