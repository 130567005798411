import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-converter";
import "@tensorflow/tfjs-backend-webgl";
import * as bodySegmentation from "@tensorflow-models/body-segmentation";

const selfieModel =
  bodySegmentation.SupportedModels.MediaPipeSelfieSegmentation;

export const removeBgFromFile = async (file: File) => {
  const src_image: HTMLImageElement | null = await getImageFrom(file);
  if (!src_image) return false;
  debugger;
  const mainCanvas = document.createElement("canvas");
  const context = mainCanvas.getContext("2d");
  mainCanvas.width = src_image.width;
  mainCanvas.height = src_image.height;
  context && context.drawImage(src_image, 0, 0);
  const resultImage = await removeBackground(mainCanvas);
  return {
    image: resultImage,
    dimension: { w: src_image.width, h: src_image.height },
  };
};

/**
 *Remove background from file
 *
 * @param {*} file
 * @param {*} method
 */
const removeBackground = async (canvas: any) => {
  //Create Segmeter with the selfie model
  const selfieSegmenter = await bodySegmentation.createSegmenter(selfieModel, {
    runtime: "tfjs",
  });
  //run segmentation
  const selfieSegmentation = await selfieSegmenter.segmentPeople(canvas);
  //Draw the mask with the sourounding black and the person part transparent
  const foregroundColor = { r: 0, g: 0, b: 0, a: 0 };
  const backgroundColor = { r: 0, g: 0, b: 0, a: 255 };
  const selfieMask = await bodySegmentation.toBinaryMask(
    selfieSegmentation,
    foregroundColor,
    backgroundColor
  );
  //Create a temporary canvas to draw the mask
  const tempCanvas = document.createElement("canvas");
  tempCanvas.width = canvas.width;
  tempCanvas.height = canvas.height;

  const context = canvas.getContext("2d");
  // Draw the mask with blur
  await bodySegmentation.drawMask(
    tempCanvas,
    tempCanvas,
    selfieMask,
    1,
    2,
    false
  );
  // we put the canvas in destination out to remove the tem canvas from the main
  context.globalCompositeOperation = "destination-out";
  context.drawImage(tempCanvas, 0, 0, canvas.width, canvas.height);
  // var imageData = canvas.toDataURL("image/jpg");

  context.restore();

  const correctCanvas = document.createElement("canvas");
  correctCanvas.width = canvas.width - 4;
  correctCanvas.height = canvas.height - 4;
  const correctContext = correctCanvas.getContext("2d");
  correctContext &&
    correctContext.drawImage(
      canvas,
      0,
      0,
      canvas.width - 3,
      canvas.height - 3,
      -3,
      -3,
      canvas.width,
      canvas.height
    );

  // we return the image
  tempCanvas.remove();
  var imageData = correctCanvas.toDataURL("image/jpg");
  return imageData;
};

/**
 * Return Image give a file
 *
 * @param {File} file
 * @returns
 */
const getImageFrom = (file: File): Promise<HTMLImageElement | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = (event) => {
      const src_image = new Image();
      src_image.onload = () => {
        resolve(src_image);
      };
      src_image.onerror = () => {
        reject();
      };
      src_image.src = event.target ? (event.target.result as any) : null;
    };
    reader.onerror = () => {
      reject();
    };
    //we load the file
    reader.readAsDataURL(file);
  });
};
