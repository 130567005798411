import { useState } from "react";
import { Modal, Button, Group } from "@mantine/core";
import { backgrounds } from "./background.const";
import "./background.modal.css";
// const backgrounds = [
//   `linear-gradient(to right, #bdc3c7, #2c3e50)`,
//   `linear-gradient(to right, #12c2e9, #c471ed, #f64f59)`,
//   `linear-gradient(to right, #f64f59, #c471ed, #12c2e9)`,
// ];

export default function BackgroundModal({
  setBackGround,
}: {
  setBackGround: any;
}) {
  // const [opened, setOpened] = useState(false);
  const renderBackground = () => {
    debugger;
    return backgrounds.map((item, index) => (
      <div
        className="background-sample"
        style={{ backgroundImage: `${item}` }}
        onClick={() => {
          setBackGround(item);
        }}
      ></div>
    ));
  };

  return (
    <div className="background-main-container">
      {/* <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Choose a background"
        overlayOpacity={0}
      > */}
      <div style={{ textAlign: "left" }}>Choose a background</div>
      <div className="background-sample-container">{renderBackground()}</div>
      {/* </Modal> */}

      {/* <Group position="center">
        <Button onClick={() => setOpened(true)}>background</Button>
      </Group> */}
    </div>
  );
}
