import bgbeach from "./assets/bg-beach.png";
import bgbeach2 from "./assets/bg-beach2.png";
import bgbeach3 from "./assets/bg-beach3.png";
import bgbeach4 from "./assets/bg-beach4.png";
import bgbeach5 from "./assets/bg-beach5.png";
import bgbeach6 from "./assets/bg-beach6.png";

import bgcascade from "./assets/bg-cascade.png";
import bgforest from "./assets/bg-forest.png";
import bglagos from "./assets/bg-lagos.png";
import bgmontain from "./assets/bg-montain.png";
import bgoffice2 from "./assets/bg-office2.png";
import bgoffice from "./assets/bg-office.png";
import bgOffice3 from "./assets/bg-office3.png";
import bgOffice4 from "./assets/bg-office4.png";
import bgriver from "./assets/bg-river.png";
import bgriviera from "./assets/bg-riviera.png";
import bglights from "./assets/bg-lights.png";
import bgabstract from "./assets/bg-abstract.png";
import bgabstract2 from "./assets/bg-abstract2.png";
import bgabstract3 from "./assets/bg-abstract3.png";
import bgabstract4 from "./assets/bg-abstract4.png";
import bgabstract5 from "./assets/bg-abstract5.png";
import bgabstract6 from "./assets/bg-abstract6.png";
import bgabstract7 from "./assets/bg-abstract7.png";
import bgabstract8 from "./assets/bg-abstract8.png";
import bgabstract9 from "./assets/bg-abstract9.png";
import bgabstract10 from "./assets/bg-abstract10.png";
import bgabstract11 from "./assets/bg-abstract11.png";
import bgabstract12 from "./assets/bg-abstract12.png";
import bgabstract13 from "./assets/bg-abstract13.png";
import bgabstract14 from "./assets/bg-abstract14.png";
import bgabstract15 from "./assets/bg-abstract15.png";
import bgabstract16 from "./assets/bg-abstract16.png";
import bgabstract17 from "./assets/bg-abstract17.png";
import bgabstract18 from "./assets/bg-abstract18.png";
import bgabstract19 from "./assets/bg-abstract19.png";
import bgabstract20 from "./assets/bg-abstract20.png";
import bgabstract21 from "./assets/bg-abstract21.png";

import bgnature1 from "./assets/bg-nature1.png";
import bgnature2 from "./assets/bg-nature2.png";
import bgnature3 from "./assets/bg-nature3.png";
import bgnature4 from "./assets/bg-nature4.png";
import bgnature5 from "./assets/bg-nature5.png";
import bgnature6 from "./assets/bg-nature6.png";
import bgnature7 from "./assets/bg-nature7.png";
import bgnature8 from "./assets/bg-nature8.png";
import bgnature9 from "./assets/bg-nature9.png";
import bgnature10 from "./assets/bg-nature10.png";

import bgtech1 from "./assets/bg-tech1.png";
import bgtech2 from "./assets/bg-tech2.png";
import bgtech3 from "./assets/bg-tech3.png";
import bgtech4 from "./assets/bg-tech4.png";
import bgtech5 from "./assets/bg-tech5.png";

import bgUniverse1 from "./assets/bg-universe1.png";
import bgUniverse2 from "./assets/bg-universe2.png";
import bgUniverse3 from "./assets/bg-universe3.png";
import bgUniverse4 from "./assets/bg-universe4.png";
import bgUniverse5 from "./assets/bg-universe5.png";
import bgUniverse6 from "./assets/bg-universe6.png";

export const backgrounds = [
  `linear-gradient(to right, #bdc3c7, #2c3e50)`,
  `linear-gradient(to right, #12c2e9, #c471ed, #f64f59)`,
  `linear-gradient(to right, #f64f59, #c471ed, #12c2e9)`,
  `linear-gradient(to right, #c6ffdd, #fbd786, #f7797d)`,
  `linear-gradient(to right, #373b44, #4286f4)`,
  `linear-gradient(to right, #2980b9, #6dd5fa, #ffffff)`,
  `linear-gradient(to right, #ff0099, #493240)`,
  `linear-gradient(to right, #aa4b6b, #6b6b83, #3b8d99)`,
  `linear-gradient(to right, #8e2de2, #4a00e0)`,
  `linear-gradient(to right, #1f4037, #99f2c8)`,
  `linear-gradient(to right, #f953c6, #b91d73)`,
  `linear-gradient(to right, #7f7fd5, #86a8e7, #91eae4)`,
  `linear-gradient(to right, #c31432, #240b36)`,
  `linear-gradient(to right, #f12711, #f5af19)`,
  `linear-gradient(to right, #659999, #f4791f)`,
  `linear-gradient(to right, #dd3e54, #6be585)`,
  `linear-gradient(to right, #8360c3, #2ebf91)`,
  `linear-gradient(to right, #544a7d, #ffd452)`,
  `linear-gradient(to right, #8360c3, #2ebf91)`,
  `linear-gradient(to right, #544a7d, #ffd452)`,
  `linear-gradient(to right, #ff416c, #ff4b2b)`,
  `linear-gradient(to right, #8a2387, #e94057, #f27121)`,
  `linear-gradient(to right, #a8ff78, #78ffd6)`,
  `linear-gradient(to right, #1e9600, #fff200, #ff0000)`,
  `linear-gradient(to right, #00c6ff, #0072ff)`,
  `linear-gradient(to right, #fbd3e9, #bb377d)`,
  `linear-gradient(235deg,#ffa3ac,#ffa3ac 40%,#00043c calc(40% + 1px),#00043c 60%,#005d6c calc(60% + 1px),#005d6c 70%,#00c9b1 calc(70% + 1px),#00c9b1),linear-gradient(125deg,#ffa3ac,#ffa3ac 40%,#00043c calc(40% + 1px),#00043c 60%,#005d6c calc(60% + 1px),#005d6c 70%,#00c9b1 calc(70% + 1px),#00c9b1)!important`,
];

export const backgroundsImages = [
  `url(${bgbeach})`,
  `url(${bgbeach2})`,
  `url(${bgbeach3})`,
  `url(${bgbeach4})`,

  `url(${bgbeach5})`,
  `url(${bgbeach6})`,

  `url(${bgcascade})`,
  `url(${bgforest})`,
  `url(${bglagos})`,
  `url(${bgmontain})`,
  `url(${bgoffice})`,
  `url(${bgoffice2})`,
  `url(${bgOffice3})`,
  `url(${bgOffice4})`,

  `url(${bgriver})`,
  `url(${bgriviera})`,
  `url(${bglights})`,

  `url(${bgnature1})`,
  `url(${bgnature2})`,
  `url(${bgnature3})`,
  `url(${bgnature4})`,
  `url(${bgnature5})`,
  `url(${bgnature6})`,
  `url(${bgnature7})`,
  `url(${bgnature8})`,
  `url(${bgnature9})`,
  `url(${bgnature10})`,

  `url(${bgabstract})`,
  `url(${bgabstract2})`,
  `url(${bgabstract3})`,
  `url(${bgabstract4})`,
  `url(${bgabstract5})`,
  `url(${bgabstract6})`,
  `url(${bgabstract7})`,
  `url(${bgabstract8})`,
  `url(${bgabstract9})`,
  `url(${bgabstract10})`,
  `url(${bgabstract11})`,
  `url(${bgabstract12})`,
  `url(${bgabstract13})`,
  `url(${bgabstract14})`,
  `url(${bgabstract15})`,
  `url(${bgabstract16})`,
  `url(${bgabstract17})`,
  `url(${bgabstract18})`,
  `url(${bgabstract19})`,
  `url(${bgabstract20})`,
  `url(${bgabstract21})`,

  `url(${bgtech1})`,
  `url(${bgtech2})`,
  `url(${bgtech3})`,
  `url(${bgtech4})`,
  `url(${bgtech5})`,

  `url(${bgUniverse1})`,
  `url(${bgUniverse2})`,
  `url(${bgUniverse3})`,
  `url(${bgUniverse4})`,
  `url(${bgUniverse5})`,
  `url(${bgUniverse6})`,
];
