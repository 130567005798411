import crock from "./assets/crock.svg";
import raySol from "./assets/raysol.svg";
import circleOut from "./assets/circleout.svg";
import digital from "./assets/digital.svg";
import flowers from "./assets/flowers.svg";
import montains from "./assets/montains.svg";
import roundlines from "./assets/roundlines.svg";
import fractal1 from "./assets/fractal1.svg";
import j007 from "./assets/007.svg";
import j008 from "./assets/008.svg";
import radialBubble from "./assets/radial-bubble.svg";
import buble from "./assets/buble.svg";
import pattern1 from "./assets/pattern1.svg";
import pattern2 from "./assets/pattern2.svg";
import pattern3 from "./assets/pattern3.svg";
import pattern4 from "./assets/pattern4.svg";
import pattern5 from "./assets/pattern5.svg";
import pcpattern1 from "./assets/pc-pattern1.svg";
import pcpattern2 from "./assets/pc-pattern2.svg";
import pcpattern3 from "./assets/pc-pattern3.svg";
import pcpattern4 from "./assets/pc-pattern4.svg";
import pcpattern5 from "./assets/pc-pattern5.svg";
import pcpattern6 from "./assets/pc-pattern6.svg";
import pcpattern7 from "./assets/pc-pattern7.svg";
import pcpattern8 from "./assets/pc-pattern8.svg";
import pcpattern9 from "./assets/pc-pattern9.svg";
import pcpattern10 from "./assets/pc-pattern10.svg";
import pcpattern11 from "./assets/pc-pattern11.svg";
import pcpattern12 from "./assets/pc-pattern12.svg";
import pcpattern13 from "./assets/pc-pattern13.svg";

const patterns = [
  null,
  crock,
  raySol,
  circleOut,
  roundlines,
  flowers,
  digital,
  montains,
  fractal1,
  j007,
  j008,
  radialBubble,
  buble,
  pattern1,
  pattern2,
  pattern3,
  pattern4,
  pattern5,
  pcpattern1,
  pcpattern2,
  pcpattern3,
  pcpattern4,
  pcpattern5,
  pcpattern6,
  pcpattern7,
  pcpattern8,
  pcpattern9,
  pcpattern10,
  pcpattern11,
  pcpattern12,
  pcpattern13,
];

export default patterns;
